import React from 'react'
import Swiper from '../../../shared/swiper'
import Img from 'gatsby-image'
import Image from '../../../image'
import {GalleryWrap} from './gallery.stc'

const Gallery = ({images, swiperSettings}) => {

    return (
        <GalleryWrap>
            <Swiper settings={swiperSettings}>
                {images && images.map((image, i) => {
                    const imageSrc = image.childImageSharp;
                    const title = 'gallery';

                    let blogImage;
                    if( image.sizes && typeof image.sizes !== 'function'){
                        blogImage = <Img sizes={image.sizes} alt={title}/>;
                    } else if(imageSrc.fixed && typeof imageSrc.fixed !== 'function'){
                        blogImage = <Img fixed={imageSrc.fixed} alt={title}/>;
                    } else if(imageSrc.fluid){
                        blogImage = <Image fluid={imageSrc.fluid} alt={title}/>
                    } else{
                        blogImage = <img src={imageSrc} alt={title}/>
                    }
                    return (
                        <div className="item" key={`gallery-${i}`}>
                            {blogImage}
                        </div>
                    )
                })}
            </Swiper>
        </GalleryWrap>
    )
}

Gallery.defaultProps = {
    swiperSettings: {
        autoplay: {
            delay: 2500,
        },
        arrows: true
    }
}

export default Gallery
