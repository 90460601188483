const getSiblings = function (elem) {
    var siblings = [];
    var sibling = elem.parentNode.firstChild;
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
};

const getClosest = function (elem, selector) {
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
};

const slugify = function(text) {
    if(!text) return;
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') 		// Replace spaces with -
      .replace(/[^\w-]+/g, '') 	// Remove all non-word chars
      .replace(/--+/g, '-') 		// Replace multiple - with single -
      .replace(/^-+/, '') 		// Trim - from start of text
      .replace(/-+$/, '') 		// Trim - from end of text
}

const createList = ({list, separator = ","}) =>{
    if(!list) return;
    return list.map((text, index) => {
        let sep;
        if(list.length !== index + 1){
            sep = separator;
        }
        return {text, sep}
    })
} 

const flatDeep = (arr, d = 1) => {
    return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
                 : arr.slice();
};

const getPostsFromQuery = (posts) => {
    if (posts) {
      return posts.edges
        .map(edge => edge.node)
        .map(node => Object.assign({}, node.frontmatter, node.fields, { excerpt: node.excerpt }))
    }
  
    return []
}

const truncateString = (str, num, dots = true) => {
    let endDots = dots ? '...' : '';
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + endDots
}

const getCategoriesSummaryFromPosts = (categories) => {    
    if (categories !== null) {
        let catArr = [];
        for(let i = 0; i<categories.length; i++){
            catArr.push(categories[i].node.frontmatter.category)
        }
        const catFlat = flatDeep(catArr);
        var catObj = catFlat.reduce((obj, b) => {
            obj[b] = ++obj[b] || 1;
            return obj;
        }, {});
        return catObj
    }

    return {}
}

const getFeaturePostsFromEdges = ( contentfulPosts ) => {
    return contentfulPosts.map( ({ node }) => {
        const {text: quote_text, author: quote_author} = node.quote !== null ? node.quote : { };
    
        return {
            node: {
              fields: {
                  slug: node.slug,
                  collection: 'contentfulPosts',
                  dateSlug: node.dateSlug,
              },
              frontmatter: {
                  sortKey: node.sortKey,
                  date: node.date,
                  format: node.format,
                  title: node.title,
                  video_link: node.video_link,
                  quote_text: quote_text,
                  quote_author: quote_author,
                  link: node.link,
                  image: node.image,
                  images: node.images,
                  category: node.category,
                  is_featured: node.is_featured,
                  recentImage: node.recentImage,
              },
              excerpt: node.body.childMarkdownRemark.excerpt,
          }
        }
    });
};

const getRecentPostsFromEdges = (recentFilesystemPosts, recentContentfulPosts) => {
    const filesystemPosts = recentFilesystemPosts || []
    const contentfulPosts = recentContentfulPosts.map( ({ node }) => {
      return {
          node: {
            fields: {
                slug: node.slug,
                collection: 'contentfulPosts',
                dateSlug: node.dateSlug,
            },
            frontmatter: {
                sortKey: node.sortKey,
                date: node.date,
                format: node.format,
                title: node.title,
                image: node.image,
            }
        }
      }
    });

    var posts = filesystemPosts.concat(contentfulPosts).sort( (lhs, rhs) => {
        if(lhs.node.frontmatter.sortKey < rhs.node.frontmatter.sortKey) {
            return 1
            }
            if(lhs.node.frontmatter.sortKey > rhs.node.frontmatter.sortKey) {
            return -1
            }
            return 0
        }
    )

    if( posts !== null ){
        return posts.slice(0, 4)

        // return posts.slice(0, 4).map( ({ node }) => {
        //     return {
        //         node: {
        //             fields: {
        //                 slug: node.fields.slug,
        //                 collection: node.fields.collection,
        //                 dateSlug: node.fields.dateSlug,
        //             },
        //             frontmatter: {
        //                 date: node.frontmatter.date,
        //                 title: node.frontmatter.title,
        //                 format: node.frontmatter.format,
        //                 recentImage: node.frontmatter.recentImage,
        //             }
        //         }
        //     }
        // });
    }

    return [];
}

const getDateSlugFromDate = (date) => {    
    var year_str = date.getFullYear();

    var month_str = 1 + date.getMonth();
    month_str = ('0' + month_str).slice(-2);
  
    var day_str = date.getDate();
    day_str = ('0' + day_str).slice(-2);
  
    var format_str = 'YYYY-MM-DD';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    
    return format_str;
};

module.exports = { slugify, getSiblings, getClosest, createList, flatDeep, getPostsFromQuery, truncateString, getCategoriesSummaryFromPosts, getRecentPostsFromEdges, getFeaturePostsFromEdges, getDateSlugFromDate}